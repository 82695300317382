import {useEffect} from 'react'
import HeaderSection from "./components/HeaderSecttion";
import MainContentSection from "./components/MainContentSection";
import StakeSection from "./components/StakeSection";
import FooterSection from "./components/FooterSection";
const HomeScreen = () => {

  useEffect(() => {
    if (window.location.href.includes('?ref=')) {
      let getAddress = window.location.href.split('?ref=')[1];
      let final = getAddress;
      localStorage.setItem('baseRef', final);
  }
  }, [])
  return (
    <>
      <HeaderSection />
      <MainContentSection />
      <StakeSection />
      <FooterSection />
    </>
  );
};

export default HomeScreen;

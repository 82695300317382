const HistoryItem = ({tx}) => {
  return (
    <div className="historyItem d-flex align-items-center justify-content-between">
      <p>{tx.time[0]} <br/> {tx.time[1]}</p>
      <p>{tx.plan} days</p>
      <p>{tx.status}</p>
      <p>{tx.amount} BNB</p>
    </div>
  );
};

export default HistoryItem;

import { useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import { walletAddressSlicer } from "../utils/util";
import Logo from "../assets/img/BNBStake.png";
const HeaderSection = () => {
  const { handleConnectToWallet, account, handleDisconnectWallet } =
    useContext(BlockchainContext);
  return (
    <header className="header-section">
      <div className="container w-100">
        <div className="header-holder align-items-center justify-content-between w-100">
          <img src={Logo} alt="bnb yields" className="logo" />
          {account ? (
            <div className="d-flex right-header-holder flex-wrap align-items-center gap-2">
              <p className="account-address">{walletAddressSlicer(account)}</p>
              <button
                onClick={() => handleDisconnectWallet("wallet")}
                className="connect-button"
              >
                Disconnect
              </button>
            </div>
          ) : (
            <button
              onClick={() => handleConnectToWallet()}
              className="connect-button"
            >
              Connect
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderSection;

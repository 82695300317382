import {walletAddressSlicer, timeDifference} from '../utils/util'
const ToastMsg = ({receipt,type}) => {
  return (
    <>
      <p className="big">
        <span className="key">Your Receipt</span>
      </p>
      <p className="small">
        <span className="key">TransactionHash: </span>{" "}
        <a
          href={process.env.REACT_APP_CONTRACT_EXPLORERLINK+`tx/${receipt.blockHash}`}
          target="_blank"
        >
          <b className="value">
            {" "}
            {walletAddressSlicer(receipt.blockHash)}
          </b>
        </a>
      </p>
      <p className="small">
        <span className="key">Status:</span>{" "}
        <b className="value">{receipt.status && "Success"}</b>
      </p>
      <p className="small">
        <span className="key">BlockNumber:</span>{" "}
        <b className="value">{receipt.blockNumber}</b>
      </p>
      <p className="small">
        <span className="key">Time:</span>{" "}
        <b className="value">{timeDifference(type === 'invest' ? receipt.events.NewDeposit.returnValues.time : receipt.events.Withdrawn.returnValues.time)}</b>
      </p>
      <a
        className="receipt-btn"
        href={process.env.REACT_APP_CONTRACT_EXPLORERLINK+`tx/${receipt.transactionHash}`}
        target="_blank"
      >
        Show More
      </a>
    </>
  );
};

export default ToastMsg

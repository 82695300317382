import { useRef, useEffect, useContext } from "react";
import depositIcon from "../assets/img/deposit.png";
import withdrawalIcon from "../assets/img/withdrawal.png";
import HistoryItem from "./HistoryItem";
import BlockchainContext from "../store/BlockchainContext";
import {numberWithCommas} from '../utils/util'

const HistoryModalBox = ({ handleClose }) => {
  const modalRef = useRef(null);
  const ModalContainerRef = useRef(null);
  const { handleGetHistoryInfo, historyTotalInfo, userTransactions } =
    useContext(BlockchainContext);
  const handleClickOutside = (event) => {
    if (ModalContainerRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });


  useEffect(() => {
    handleGetHistoryInfo();
  }, []);

  return (
    <>
      <div className="modalContainer" ref={ModalContainerRef}>
        <div className="modal-holder d-flex flex-wrap gap-4 popupbackground" ref={modalRef}>
          <div className="modal-left p-3 d-flex flex-column gap-5 ml-15">
            <div className="modal-left-box d-flex flex-column justify-content-center align-items-center gap-2">
              <img src={depositIcon} alt="Bnb Stake" />
              <h1>TOTAL DEPOSIT</h1>
              <p>{numberWithCommas(historyTotalInfo[0],5)} BNB</p>
            </div>
            <div className="modal-left-box d-flex flex-column justify-content-center align-items-center gap-2">
              <img src={withdrawalIcon} alt="Bnb Stake" />
              <h1>TOTAL WITHDRAW</h1>
              <p>{numberWithCommas(historyTotalInfo[1],5)} BNB</p>
            </div>
          </div>
          <div className="history-modal">
            <header className="modal_header d-flex justify-content-between">
              <h2 className="modal_header-title">Deposit History</h2>
              <button
              type="button"
              className="btn-close"
              onClick={() => handleClose()}
            ></button>
            </header>
            <main className="modal_content">
              <div className="history-head d-flex align-items-center justify-content-between">
                <p className="history-head-title">Date</p>
                <p className="history-head-title">Plan</p>
                <p className="history-head-title">Status</p>
                <p className="history-head-title">Amount</p>
              </div>
              <div className="history-item-holder d-flex flex-column gap-2 mt-2">
                {userTransactions && userTransactions.map((txInfo, index) => (
                      <HistoryItem key={index} tx={txInfo} />
                    ))
                  }
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryModalBox;
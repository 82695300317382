const TotalInfoBox = ({title,value,dollor}) => {
  return (
    <div className="total-info-box d-flex flex-column justify-content-evenly gap-2 ">
      <p className="total-info-title">{title}</p>
      <p className="total-info-value">{value} BNB</p>
      <p className="total-info-dollor">$ {dollor}</p>
    </div>
  );
};

export default TotalInfoBox;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./Connection.css";
import "./media.css";
import "./reset.css";
import "./style.css";
import App from "./App";
import { BlockchainContextProvider } from "./store/BlockchainContext";
import { HistoryModalContextProvider } from "./store/HistoryModalContext";
import HistoryModal from "./components/HistoryModal";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


ReactDOM.render(
  <BlockchainContextProvider>
    <HistoryModalContextProvider>
      <App />
      <HistoryModal />
      <ToastContainer />
    </HistoryModalContextProvider>
  </BlockchainContextProvider>,
  document.getElementById("root")
);

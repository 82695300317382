import { useContext } from "react";
import HistoryModalContext from "../store/HistoryModalContext";
import HistoryModalBox from './HistoryModalBox'
const HistoryModal = () => {
  const {isHistoryModalOpen, hideModal, showModal } = useContext(HistoryModalContext);
  return (
    <>
      {isHistoryModalOpen && (
        <HistoryModalBox
        handleClose={hideModal}
        handleOpen={showModal}
        />
      )}
    </>
  );
};
export default HistoryModal;
const LastDepositItem = ({deposit}) => {
  return (
    <div className="deposit-item">
      <div className="deposit-item-top d-flex align-items-center justify-content-between mb-1 gap-2">
        <a className="tx-hash" target='_blank' href={deposit.txUrl}>Transation Hash: {deposit.txHash}</a>
        <p className="tx-date">Date: {deposit.time[0]} - {deposit.time[1]}</p>
      </div>
      <div className="deposit-item-bottom d-flex align-items-center justify-content-between gap-2">
        <p className="wallet-address">Wallet: {deposit.user}</p>
        <p className="amount">
          Amount: <span className="deposit-value">{deposit.amount} BNB</span>
        </p>
        <p className="plan">
          Plan: <span className="plan-number">{deposit.plan} days</span>
        </p>
      </div>
    </div>
  );
};

export default LastDepositItem;

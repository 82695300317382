import FooterMainSection from "./FooterMainSection";
import FooterBottomSection from "./FooterBottomSection";
const FooterSection = () => {
  return (
    <footer>
      <FooterMainSection />
      <FooterBottomSection />
    </footer>
  );
};

export default FooterSection;

import { useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import AfflicateItem from "./AfflicateItem";
import CopyToClipboard from "react-copy-to-clipboard";
import LastDepositItem from "./LastDepositItem";

import { toast } from "react-toastify";
const StakeSection = () => {
  const {
    userInvitedInfo,
    account,
    lastDeposits,
    userTotalDeposits
  } = useContext(BlockchainContext);
  return (
    <div></div>
  );
};

export default StakeSection;

const AfflicateItem = ({ level, percent, type }) => {
  return (
    <div
      className={`afflicate-rectangle ${type} d-flex flex-column align-items-center justify-content-between`}
    >
      <p className={`rect-level ${type}`}>{level}</p>
      <p className={`rect-percent ${type}`}>{percent}</p>
    </div>
  );
};

export default AfflicateItem;

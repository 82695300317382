import { createContext, useState, useEffect } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { providers } from "../utils/Web3Provider";
import { ABI } from "../utils/abi";
import axios from "axios";
import {
  formatTimeStamp,
  walletAddressSlicer,
  numberWithCommas,
} from "../utils/util";
import { toast } from "react-toastify";
import ToastMsg from "../components/ToastMsg";

const BlockchainContext = createContext({
  notification: null,
  showModal: function () {},
  hideModal: function () {},
});

export const BlockchainContextProvider = (props) => {
  const [provider, setProvider] = useState();
  const [web3Instance, setWeb3Instance] = useState();
  //const [web3Modal, setWeb3Modal] = useState();
  const [Contract, setContract] = useState();
  const [account, setAccount] = useState();
  const [usdPrice, setUsdPrice] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState("0000.000");
  const [totalRefEarn, setRefEarn] = useState("0000.000");
  const [walletBalance, setWalletBalance] = useState("0.00000");
  const [harvestValue, setHarvestValue] = useState("0.00000");
  const [lastDeposits, setLastDeposits] = useState([]);
  const [historyTotalInfo, setHistoryTotalInfo] = useState(["0.000", "0.000"]);
  const [userTransactions, setUserTransactions] = useState([]);
  const [userInvitedInfo, setUserInvitedInfo] = useState(["0", "0"]);
  const [userTotalDeposits, setUserTotalDeposit] = useState();
  let accountAddress;
  let walletProvider;
  let transactions=[];

  useEffect(() => {
    if (localStorage.getItem("account") && localStorage.getItem("account") !== 'undefined') {
      connectToWallet("wallet");
    }else{
      connectToWallet("noWallet");
    }
  }, []);

  useEffect(() => {
    if (provider && account) {
      provider.on("accountsChanged", (accounts) => {
          accountAddress = accounts[0];
          setUserTransactions([]);
          fetchImportantData(Contract, web3Instance, accounts[0]);
      });
    }
    if(provider && account){
      provider.on("networkChanged", (networkId) => {
        setAccount('')
        setHarvestValue('')
        setWalletBalance('')
        connectToWallet()
        getLastDeposits(Contract, web3Instance)
      });
    }
   

  });

  const fetchImportantData = async (contract, web3, account) => {
    if(account){
      await contract.methods.getUserAvailable(account).call((error, result) => {
        if (!error) {
          setHarvestValue(web3.utils.fromWei(result));
        }
      });
      const WalletBl = await web3.eth.getBalance(account);
      setAccount(account)
      setWalletBalance(web3.utils.fromWei(WalletBl));
    }else{
      localStorage.removeItem('account')
      setAccount('')
    }
  };
  const getRealTimeData = async (contract, web3) => {
    setInterval(async () => {
      const account = await web3.eth.getAccounts();
      if (account[0] && accountAddress) {
        await contract.methods
          .getUserAvailable(account[0])
          .call((error, result) => {
            if (!error) {
              setHarvestValue(web3.utils.fromWei(result));
            }
          });
        const WalletBl = await web3.eth.getBalance(account[0]);
        setWalletBalance(web3.utils.fromWei(WalletBl));
      } else {
        setHarvestValue("0");
        setWalletBalance("0");
      }
    }, 3000);
  };


  const connectToWallet = async (type) => {

    if(type === "noWallet"){
      const web3 = new Web3(process.env.REACT_APP_CONTRACT_LINKRPC);
      const contractInstance = new web3.eth.Contract(
        ABI,
        process.env.REACT_APP_CONTRACT
      );
      setContract(contractInstance);
      setWeb3Instance(web3);
      setProvider(provider);
      fetchDataFromContract(contractInstance, web3, null);
    }else{
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: providers,
        theme: "dark",
      });
      let provider;
      await web3Modal
        .connect(web3Modal)
        .then((res) => {
          provider = res;
          walletProvider = res;
        })
        .catch((err) => {
          provider = process.env.REACT_APP_CONTRACT_LINKRPC;
        });
      const web3 = new Web3(provider);
      const account = await web3.eth.getAccounts();
      const contractInstance = new web3.eth.Contract(
        ABI,
        process.env.REACT_APP_CONTRACT
      );
      setContract(contractInstance);
      account.length > 0 && setAccount(account[0]);
      setWeb3Instance(web3);
      setProvider(provider);
      console.log(web3.currentProvider.accounts);
      console.log(provider.chainId);
      console.log(process.env.REACT_APP_CONTRACT_CHAINID);
      if((web3.currentProvider.selectedAddress!=null)  || (web3.currentProvider.accounts))
      {

        if(provider.chainId!=process.env.REACT_APP_CONTRACT_CHAINID)
        {
            switchnetwork(Contract, web3, account[0]);
        }
        else
        {
          //console.log(web3);
          //setWeb3Modal(web3Modal);
          fetchDataFromContract(contractInstance, web3, account[0]);
          accountAddress = account[0];
          localStorage.setItem('account',account[0]);
          fetchImportantData(contractInstance, web3, account[0]);
        }
        
      }
      else
      {

        setAccount(null);
        localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
        localStorage.removeItem("walletconnect");
        localStorage.removeItem("account")
        setProvider(null);
        accountAddress = null;
        setUserTransactions([]);
        setHarvestValue('');
        setWalletBalance('');
      }
    }
    
  };

  const fetchDataFromContract = async (contract, web3, account) => {
    const usdPrice = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd"
    );
    setUsdPrice(usdPrice.data.binancecoin.usd);
    if (contract && !account) {
      await contract.methods.getSiteInfo().call((error, result) => {
        if (!error) {
          setTotalDeposit(web3.utils.fromWei(result[0]));
          setRefEarn(web3.utils.fromWei(result[1]));
        }
      });
      getLastDeposits(contract, web3);
    }
    if (contract && account) {
      await contract.methods.getSiteInfo().call((error, result) => {
        if (!error) {
          setTotalDeposit(web3.utils.fromWei(result[0]));
          setRefEarn(web3.utils.fromWei(result[1]));
        }
      });
      await contract.methods.getUserAvailable(account).call((error, result) => {
        if (!error) {
          setHarvestValue(web3.utils.fromWei(result));
        }
      });
      await contract.methods.getUserAmountOfDeposits(account).call((error, result) => {
        if (!error) {
          setUserTotalDeposit(web3.utils.fromWei(result));
        }
      });

      const WalletBl = await web3.eth.getBalance(account);

      await contract.methods
        .getUserTotalReferrals(account)
        .call(async (error, totalRef) => {
          if (!error) {
            await contract.methods
              .getUserReferralBonus(account)
              .call((error, refBonus) => {
                if (!error) {
                  setUserInvitedInfo([totalRef, web3.utils.fromWei(refBonus)]);
                }
              });
          }
        });
        console.log(" 4444");
      setWalletBalance(web3.utils.fromWei(WalletBl));
      if (!Contract) {
        getLastDeposits(contract, web3);
      }
      getRealTimeData(contract, web3);
    }
  };

  const getLastDeposits = async (Contract, web3, step) => {
    const plansArray = [10,20,30,40,50];
    web3.eth.getBlockNumber((error, result) => {
      if (!error) {
        //console.log(result);
        //console.log(step);
        console.log(result - step);
        //console.log(process.env.REACT_APP_CONTRACT_FIRST_BLOCK);
        //console.log(Contract);
        
        if(step==null)
          step=0;
        Contract.getPastEvents("NewDeposit",{fromBlock: step == undefined ? result - 5000 : result - step,toBlock: result - step +5000,},(error, events) => {
            if (!error) {
              //console.log(events);
              //transactions = transactions.concat(events);
              transactions = events.concat(transactions);
              //console.log(transactions.length+"  "+events.length);
              //console.log(process.env);
              if (
                transactions.length < 15 &&
                result - (step== undefined?5000:step) > process.env.REACT_APP_CONTRACT_FIRST_BLOCK
              ) {
                const value = transactions.slice(-15).reverse()
                  .map((event) => {
                    return {
                      txHash: walletAddressSlicer(event.transactionHash),
                      time: formatTimeStamp(event.returnValues.time),
                      user: walletAddressSlicer(event.returnValues.user),
                      amount: numberWithCommas(
                        web3.utils.fromWei(event.returnValues.amount),
                        5
                      ),
                      plan: plansArray[event.returnValues.plan],
                      txUrl: process.env.REACT_APP_CONTRACT_EXPLORERLINK+`tx/${event.transactionHash}`,
                    };
                  });
                setLastDeposits(value);
                getLastDeposits(Contract,web3,step == undefined ? 5000 : step + 5000);
              } else {
                console.log(" else "+ transactions.length);
                const value = transactions.slice(-15).reverse()
                  .map((event) => {
                    return {
                      txHash: walletAddressSlicer(event.transactionHash),
                      time: formatTimeStamp(event.returnValues.time),
                      user: walletAddressSlicer(event.returnValues.user),
                      amount: numberWithCommas(
                        web3.utils.fromWei(event.returnValues.amount),
                        5
                      ),
                      plan: plansArray[event.returnValues.plan],
                      txUrl: process.env.REACT_APP_CONTRACT_EXPLORERLINK+`tx/${event.transactionHash}`,
                    };
                  });
                setLastDeposits(value);
              }
            }
            else
            {
              console.log(error);
            }
          }
        );
      }
      else
      {
        console.trace(error);
      }
    });
  };

  const invest = async (plan, amount) => {
    console.log(plan,amount);
    if(account){
      let refAccount;
      if (localStorage.getItem("baseRef")) {
        refAccount = localStorage.getItem("baseRef");
      } else {
        refAccount = account;
      }
      await Contract.methods.invest(refAccount, plan)
        .send(
          { from: account, value: web3Instance.utils.toWei(amount) },
          (error, result) => {
            if (!error) {
            }
          }
        )
        .on("receipt", function (receipt) {
          toast.success(<ToastMsg receipt={receipt} type="invest" />);
          fetchImportantData(Contract, web3Instance, account);
        }).catch(e => { console.error(e)});
    }
    else
    {
      console.log(" no account connected")
    }
  };

  const withdraw = async () => {
    if(account){
      await Contract.methods
        .withdraw()
        .send({ from: account }, (error, result) => {
          if (!error) {
          }
        })
        .on("receipt", function (receipt) {
          toast.success(<ToastMsg receipt={receipt} type="withdrawn" />);
          fetchImportantData(Contract, web3Instance, account);
        });
    }
  };
  const switchnetwork = async (contract, web3, account) => {
          await web3.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: process.env.REACT_APP_CONTRACT_CHAINID }]
            }).then(data=> {
              //console.log("222");
              return  connectToWallet("wallet");
            }).catch(e=> {
              console.error(e);
              return  addNetwork(contract, web3, account);
          
            });
  };
  const addNetwork = async (contract, web3, account) => {
            const data = [{
                chainId: process.env.REACT_APP_CONTRACT_CHAINID,
                chainName: 'Binance Smart Chain',
                nativeCurrency:
                    {
                        name: 'BNB',
                        symbol: 'BNB',
                        decimals: 18
                    },
                rpcUrls: [process.env.REACT_APP_CONTRACT_LINKRPC],
                blockExplorerUrls: [process.env.REACT_APP_CONTRACT_EXPLORERLINK],
              }]
             await web3.currentProvider.request({method: 'wallet_addEthereumChain', params:data}).then(data=> {
                //console.log("111");
                return  connectToWallet("wallet");
              }).catch((ex) => {
                console.error(ex);
                return null;  
              })
  };

  const disconnectWallet = async () => {
    //await web3Modal.clearCachedProvider();
    setAccount(null);
    localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("account")
    setProvider(null);
    accountAddress = null;
    setUserTransactions([]);
    setHarvestValue('');
    setWalletBalance('');
    
  };

  const getHistoryInfo = async () => {
    if (Contract) {
      await Contract.methods
        .getUserTotalDeposits(account)
        .call(async (error, deposits) => {
          if (!error) {
            await Contract.methods
              .getUserTotalWithdrawn(account)
              .call((error, Withdrawn) => {
                if (!error) {
                  setHistoryTotalInfo([
                    web3Instance.utils.fromWei(deposits),
                    web3Instance.utils.fromWei(Withdrawn),
                  ]);
                  if (userTransactions.length < 1) {
                    getUserTransactions();
                  }
                }
              });
          }
        });
    }
  };

  const getUserTransactions = async () => {
    const plansArray =[10,20,30,40,50];
    if (Contract) {
      await Contract.methods
        .getUserAmountOfDeposits(account)
        .call(async (error, amountOfDeposit) => {
          if (!error) {
            for (let i = 0; i < amountOfDeposit; i++) {
              await Contract.methods
                .getUserDepositInfo(account, i)
                .call((error, depositInfo) => {
                  if (!error) {
                    setUserTransactions((old) => [
                      ...old,
                      {
                        time: formatTimeStamp(depositInfo.start),
                        plan: plansArray[depositInfo.plan],
                        status:
                          Date.now() > depositInfo.finish * 1000
                            ? "Closed"
                            : "Active",
                        amount: numberWithCommas(
                          web3Instance.utils.fromWei(depositInfo.amount),
                          5
                        ),
                      },
                    ]);
                  }
                });
            }
          }
        });
    }
  };

  const context = {
    provider: provider,
    web3Instance: web3Instance,
    Contract: Contract,
    account: account,
    usdPrice: usdPrice,
    totalDeposit: totalDeposit,
    totalRefEarn: totalRefEarn,
    walletBalance: walletBalance,
    harvestValue: harvestValue,
    lastDeposits: lastDeposits,
    historyTotalInfo: historyTotalInfo,
    userTransactions: userTransactions,
    userInvitedInfo: userInvitedInfo,
    userTotalDeposits: userTotalDeposits,
    handleConnectToWallet: connectToWallet,
    handleDisconnectWallet: disconnectWallet,
    handleInvest: invest,
    handleWithdraw: withdraw,
    handleGetHistoryInfo: getHistoryInfo,
  };
  return (
    <BlockchainContext.Provider value={context}>
      {props.children}
    </BlockchainContext.Provider>
  );
};

export default BlockchainContext;
